<template>
  <tbody>
    <tr v-for="(item, index) in items" :key="item.id || index">
      <td v-if="item.area">{{ item.area }}</td>
      <td v-if="item.id">{{ item.id }}</td>
      <td class="text-center">{{ item.value }}</td>
      <td class="text-center">{{ item.self }}</td>
      <td class="text-center">{{ item.assistant }}</td>
      <td class="text-center">{{ item.rejected }}</td>
      <td class="text-center">
        {{
          sumCompanyKeyActivesAndActivations(
            item.value,
            item.self,
            item.assistant,
            item.rejected
          )
        }}
      </td>
    </tr>
    <tr>
      <td></td>
      <td class="text-center subtitle-1">{{ getSumColumn.value }}</td>
      <td class="text-center subtitle-1">{{ getSumColumn.self }}</td>
      <td class="text-center subtitle-1">{{ getSumColumn.assistant }}</td>
      <td class="text-center subtitle-1">{{ getSumColumn.rejected }}</td>
      <td class="text-center subtitle-1">{{ getSumColumn.total }}</td>
    </tr>
  </tbody>
</template>

<script>
export default {
  name: 'ReportListItem',
  props: {
    items: {
      type: Array,
      default() {
        return []
      }
    }
  },
  computed: {
    // Sumamos cada columna y el total de todas ellas para mostrarlos en una línea abajo del todo
    getSumColumn() {
      return this.items.reduce(
        (sumValue, item) => {
          sumValue.value += item.value
          sumValue.self += item.self
          sumValue.assistant += item.assistant
          sumValue.rejected += item.rejected
          sumValue.total +=
            item.value + item.self + item.assistant + item.rejected

          return sumValue
        },
        {
          value: 0,
          self: 0,
          assistant: 0,
          rejected: 0,
          total: 0
        }
      )
    }
  },
  methods: {
    /**
     * Obtenemos los totales por separados para sumarlos
     *
     * @param {integer} active
     * @param {integer} self
     * @param {integer} assistant
     * @param {integer} rejected
     * @return {integer}
     */
    sumCompanyKeyActivesAndActivations(active, self, assistant, rejected) {
      return active + self + assistant + rejected
    }
  }
}
</script>
