<template>
  <tbody>
    <tr v-for="(item, index) in items" :key="item.area || index">
      <td class="text-center">{{ item.area }}</td>
      <td class="text-center">
        {{ item.printed.small }} / {{ item.assigned.small }}
      </td>
      <td class="text-center">
        {{ item.printed.big }} / {{ item.assigned.big }}
      </td>
    </tr>
  </tbody>
</template>

<script>
export default {
  name: 'ResportsStickersCounterItem',
  props: {
    items: {
      type: Array,
      default() {
        return []
      }
    }
  }
}
</script>
