// Constants
import {
  ACTIVATION_TYPES,
  ACTIVATION_STATUS,
  STICKERS_STATUS,
  CARTA_URL_BAKARTA,
  CARTA_URL_SCHWEPPES
} from '@/constants'
// Utils
import { getCurrentBrand } from '@/utils'

/**
 * Url de la carta del establecimiento
 *
 * @param {string} id - UID place
 * @param {string} brand - brand place
 * @return {string}
 */
export function placeUrlByIdAndBrand(id, brand = null) {
  const currentBrand = getCurrentBrand(brand)
  const cartaUrl =
    currentBrand === 'bakarta' ? CARTA_URL_BAKARTA : CARTA_URL_SCHWEPPES
  return `${cartaUrl}/${id}`
}

/**
 * Obtenemos las etiquetas (label) de los tipos de las activaciones
 *
 * @param {string} type
 * @return {string}
 */
export function labelActivationsType(type) {
  return type && ACTIVATION_TYPES[type]
    ? ACTIVATION_TYPES[type].label || '-'
    : '-'
}

/**
 * Obtenemos las etiquetas (label) de los estados de las activaciones
 *
 * @param {string} status
 * @return {string}
 */
export function labelActivationsStatus(status) {
  return status && ACTIVATION_STATUS[status]
    ? ACTIVATION_STATUS[status].label || '-'
    : '-'
}

/**
 * Obtenemos las etiquetas (label) de los estados
 * de los pedidos de pegatinas
 *
 * @param {string} status
 * @return {string}
 */
export function labelStickersStatus(status) {
  return status && STICKERS_STATUS[status]
    ? STICKERS_STATUS[status].label || '-'
    : '-'
}
