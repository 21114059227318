<template>
  <PanelSection title="Contadores generales">
    <v-container>
      <v-card class="elevation-0 mb-4">
        <v-card-text>
          <v-card-title class="pt-0">
            <span>Resumen de Etiquetas QR</span>
          </v-card-title>
          <ReportsStickersCounter
            v-if="getTraderAreas()"
            :areas="getTraderAreas()"
          />
        </v-card-text>
      </v-card>
      <v-card class="elevation-0 mb-4">
        <v-card-title class="pt-0">
          <!-- Range dates -->
          <VuetifyDatePicker
            :date="date"
            :allowed-dates="allowedDates"
            @onChangeDate="handleChangeDate"
          />
        </v-card-title>
        <v-card-text>
          <ContentLoading
            v-if="!companyKeysKPI"
            :loading="loading"
            class="mb-6"
          />
          <div v-else class="elevation-0">
            <!-- Tabla de KPIs por áreas -->
            <div v-if="isAdmin">
              <v-card-title>
                <span>Resumen de uso total por Áreas</span>
                <v-spacer />
                <v-btn
                  :loading="downloadLoadingKPIs['all']"
                  class="elevation-0 secondary mr-2"
                  small
                  @click="exportKPIsToExcel('all')"
                >
                  Exportar KPIs
                </v-btn>
                <v-btn
                  :loading="downloadLoading['all']"
                  class="elevation-0 primary"
                  small
                  @click="exportToExcel('all')"
                >
                  Exportar detalles
                </v-btn>
              </v-card-title>
              <CustomDataTable
                :headers="headersDataByArea"
                :items="companyKeysKPI"
                :loading="loading"
                :data-table-v-options="{
                  'show-custom-paginate': false,
                  dense: true
                }"
              >
                <template slot-scope="{ items }">
                  <ReportsListItem :items="items" />
                </template>
              </CustomDataTable>
            </div>
            <!-- Tabla de KPIs por áreas y gerencias -->
            <div class="mb-6" :class="{ 'mt-8': isAdmin }">
              <v-card-title>
                <span>Resumen de uso total en detalle por Gerencia</span>
              </v-card-title>

              <div
                v-for="(companyKey, i) in companyKeysKPI"
                :key="`company-keys-area-${i}`"
              >
                <v-card-title>
                  <h5>Área {{ companyKey.area }}</h5>
                  <v-spacer />
                  <v-btn
                    :loading="downloadLoadingKPIs[companyKey.area]"
                    class="elevation-0 secondary mr-2"
                    small
                    @click="exportKPIsToExcel(companyKey.area)"
                  >
                    Exportar KPIs - área {{ companyKey.area }}
                  </v-btn>
                  <v-btn
                    :loading="downloadLoading[companyKey.area]"
                    class="elevation-0 primary"
                    small
                    @click="exportToExcel(companyKey.area)"
                  >
                    Exportar detalles - área {{ companyKey.area }}
                  </v-btn>
                </v-card-title>
                <div class="mb-6">
                  <CustomDataTable
                    :headers="headersDataByManagement"
                    :items="companyKey.managements"
                    :loading="loading"
                    :data-table-v-options="{
                      'show-custom-paginate': false,
                      dense: true
                    }"
                  >
                    <template slot-scope="{ items }">
                      <ReportsListItem :items="items" />
                    </template>
                  </CustomDataTable>
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-container>
  </PanelSection>
</template>

<script>
// Constants
import { TRADER_AREAS, ROLES } from '@/constants'
// Components
import PanelSection from '@/components/ui/PanelSection'
import VuetifyDatePicker from '@/components/ui/VuetifyDatePicker'
import CustomDataTable from '@/components/ui/CustomDataTable'
import ContentLoading from '@/components/ui/ContentLoading'
import ReportsListItem from '@/components/elements/reports/ReportsListItem'
import ReportsStickersCounter from '@/components/elements/reports/ReportsStickersCounter'
// import TableTotalKpis from '@/components/elements/reports/TableTotalKpis'
// Services
import { getCompanyKeysKPITotal } from '@/services/reports'
import { getAllCompanyKeysActivatedAndWithCompanyActivationToExcel } from '@/services/companyKeys'
// Filters
import { labelActivationsType } from '@/filters/app'
// Vuex
import { mapGetters } from 'vuex'
// Utils
import { get } from 'lodash'

const moment = require('moment')

export default {
  name: 'ReportsList',
  components: {
    PanelSection,
    VuetifyDatePicker,
    CustomDataTable,
    ContentLoading,
    ReportsListItem,
    ReportsStickersCounter
  },
  filters: { labelActivationsType },
  data() {
    return {
      // Data statistic
      date: [
        moment('2020-06-01').format('YYYY-MM-DD'),
        moment()
          .subtract(1, 'days')
          .format('YYYY-MM-DD')
      ],
      horecaStatistics: null,
      companyKeysKPI: null,
      loading: false,
      headersDataByArea: [
        { text: 'Área', value: 'area' },
        { text: 'Activados', value: 'value', align: 'center' },
        { text: 'Auto-Activaciones', value: 'self', align: 'center' },
        { text: 'Asistidas', value: 'assistant', align: 'center' },
        { text: 'En Revisión', value: 'rejected', align: 'center' },
        { text: 'Total', value: '', align: 'center' }
      ],
      headersDataByManagement: [
        { text: 'Gerencia', value: 'id' },
        { text: 'Activados', value: 'value', align: 'center' },
        { text: 'Auto-Activaciones', value: 'self', align: 'center' },
        { text: 'Asistidas', value: 'assistant', align: 'center' },
        { text: 'En Revisión', value: 'rejected', align: 'center' },
        { text: 'Total', value: '', align: 'center' }
      ],
      downloadLoadingKPIs: {
        all: false,
        1: false,
        2: false,
        3: false,
        4: false,
        6: false
      },
      downloadLoading: {
        all: false,
        1: false,
        2: false,
        3: false,
        4: false,
        6: false
      }
    }
  },
  computed: {
    ...mapGetters('authentication', ['userData']),
    /**
     * Am I an Admin?
     *
     * @return {boolean}
     */
    isAdmin() {
      return this.userData.role === ROLES.admin.value
    },
    /**
     * Get total companykeys
     *
     * @return {number} - total number
     */
    getTotalCompanyKeysKPI() {
      return Array.isArray(this.companyKeysKPI)
        ? this.companyKeysKPI.reduce((sumTotal, kpi) => {
            sumTotal += kpi.value
            return sumTotal
          }, 0)
        : 0
    }
  },
  async mounted() {
    // await this.setHorecaStatistics()
    this.loading = true
    await this.setCompanyKeysKPI(this.date)
    this.loading = false
  },
  methods: {
    /**
     * Handle date change
     *
     * @param {array} date - current date
     */
    async handleChangeDate(date) {
      this.loading = true
      this.date = [
        moment(date[0]).format('YYYY-MM-DD'),
        moment(date[1]).format('YYYY-MM-DD')
      ]
      await this.setCompanyKeysKPI(this.date)
      this.loading = false
    },
    /**
     * Allowed dates to select in calendar
     *
     * @param {string} date - date format
     * @return {boolean}
     */
    allowedDates(date) {
      return moment().diff(moment(date), 'days') > 0
    },
    /**
     * Get current areas depending logged user
     *
     * @return {array} - areas
     */
    getTraderAreas() {
      if (this.isAdmin) {
        return TRADER_AREAS
      }

      return get(this.userData, 'meta.areas', [])
    },
    /**
     * Set data from horeca's statistics (companyKeys KPI)
     *
     * @param {array} date - current date
     */
    async setCompanyKeysKPI(date) {
      const dateFrom = moment(date[0])
        .startOf('day')
        .toDate()
      const dateTo = moment(date[1])
        .endOf('day')
        .toDate()

      this.companyKeysKPI = await Promise.all(
        this.getTraderAreas().map(async area => {
          const companyKeysKPIArea = await getCompanyKeysKPITotal(
            dateFrom,
            dateTo,
            area
          )
          return companyKeysKPIArea
        })
      )
    },
    // Export companyKeysKPI to excel
    async exportKPIsToExcel(area) {
      this.downloadLoadingKPIs[area] = true
      // Cargamos las fechas para usarlas en el nombre del fichero a exportar
      const dateFrom = moment(this.date[0]).toDate()
      const dateTo = moment(this.date[1]).toDate()

      // Cargamos el array de companyKeysKPI que ya tenemos en arrayData
      let arrayData = this.companyKeysKPI
      // El título de la primeras celda, por defecto se llamará Área
      let firstHeadTitle = 'Área'
      /*
       * Si se especifíca un área, tenemos que mostrar los datos por gerencia
       * por lo que reescribimos arrayData con el array de la gerencia
       * el título de la primera celda pasa a llamerse gerencia
       */
      if (area !== 'all') {
        const KPIIndex = this.companyKeysKPI.findIndex(kpi => kpi.area === area)
        arrayData = this.companyKeysKPI[KPIIndex].managements
        firstHeadTitle = 'Gerencia'
      }

      import('@/vendor/Export2Excel').then(excel => {
        // Definimos las cabeceras
        const tHeader = [
          firstHeadTitle,
          'Activados',
          'Auto-Activados',
          'Asistidas',
          'En Revisión',
          'Total'
        ]
        // Definimos en el mismo orden los nombres de los campos que corresponden a las cabeceras
        const filterVal = [
          'area',
          'value',
          'self',
          'assistant',
          'rejected',
          'total'
        ]

        // Mapeamos cada dato con su cabecera
        const data = arrayData.map(v =>
          filterVal.map(j => {
            // Si el campo es total returnamos la suma de cada tipo
            if (j === 'total') {
              return v.value + v.self + v.assistant + v.rejected
            }
            /*
             * Si la consulta es de un área en concreto el valor a devolver
             * es la id de la gerencia en vez del área
             */
            if (area !== 'all' && j === 'area') {
              j = 'id'
            }
            return v[j]
          })
        )
        // Declaramos el nombre del fichero resultante usando las fechas y si es de un área en concreto o general
        const filename =
          area === 'all'
            ? `KPIs Cartas Digitales desde 
              ${moment(dateFrom).format('DD-MM-YYYY')} 
              a ${moment(dateTo).format('DD-MM-YYYY')}`
            : `KPIs Cartas Digitales por gerencia del área ${area} desde 
              ${moment(dateFrom).format('DD-MM-YYYY')} 
              a ${moment(dateTo).format('DD-MM-YYYY')}`
        // Envíamos los datos a la librería para que genere el excel
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename,
          autoWidth: true,
          bookType: 'xlsx'
        })

        this.downloadLoadingKPIs[area] = false
      })
    },
    /**
     * Construimos el array de companyKeys tanto activadas como con solicitud y
     * con el detalle de cada área, para preparar un array de datos y exportar a excel
     */
    async exportToExcel(area) {
      this.downloadLoading[area] = true

      let areas = []
      if (area === 'all') {
        areas = this.getTraderAreas()
      } else {
        areas.push(area)
      }
      const dateFrom = moment(this.date[0])
        .startOf('day')
        .toDate()
      const dateTo = moment(this.date[1])
        .endOf('day')
        .toDate()

      /**
       * Llamamos al servicio que nos devuelve un array con todos los companyKeys activados y con solicitudes
       * teniendo en cuanta las areas y fechas seleccionadas
       */
      const companyKeysKPIsDetailsToExcel = await getAllCompanyKeysActivatedAndWithCompanyActivationToExcel(
        dateFrom,
        dateTo,
        areas
      )

      // Usamos este vendor para exportar a excel (sacado del foro de vue)
      import('@/vendor/Export2Excel').then(excel => {
        // Definimos las cabeceras
        const tHeader = [
          'Área',
          'Gerencia',
          'Promotor',
          'Ruta',
          'Cliente',
          'Nombre comercial',
          'Estado',
          // 'Tipo Solicitud Original',
          // 'Tipo Solicitud Final',
          'Fecha Solicitud',
          'Fecha Activación',
          'Incidencia'
        ]
        // Definimos en el mismo orden los nombres de los campos que corresponden a las cabeceras
        const filterVal = [
          'area',
          'management',
          'promoter',
          'route',
          'code',
          'companyName',
          'status',
          // 'originalRequest',
          // 'finalRequest',
          'dateCreateRequest',
          'dateCreateActivation',
          'incidence'
        ]
        // Mapeamos cada dato con su cabecera (así lo hace el plugin de ejemplo)
        const data = companyKeysKPIsDetailsToExcel.map(v =>
          filterVal.map(j => {
            return v[j]
          })
        )
        // Declaramos el nombre del fichero resultante usando las fechas y si es de un área en concreto o general
        const filename =
          area === 'all'
            ? `Listado de clientes Cartas Digitales desde 
              ${moment(dateFrom).format('DD-MM-YYYY')} a 
              ${moment(dateTo).format('DD-MM-YYYY')}`
            : `Listado de clientes Cartas Digitales del área ${area} desde 
              ${moment(dateFrom).format('DD-MM-YYYY')} 
              a ${moment(dateTo).format('DD-MM-YYYY')}`
        // Envíamos los datos a la librería para que genere el excel
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename,
          autoWidth: true,
          bookType: 'xlsx'
        })
        this.downloadLoading[area] = false
      })
    }
  }
}
</script>
