<template>
  <div>
    <CustomDataTable
      v-if="stickersCounters"
      :headers="headers"
      :items="stickersCounters"
      :loading="false"
      :data-table-v-options="{
        'show-custom-paginate': false,
        dense: true
      }"
    >
      <template slot-scope="{ items }">
        <ResportsStickersCounterItem :items="items" />
      </template>
    </CustomDataTable>
  </div>
</template>

<script>
// Services
import { getStickersCounter } from '@/services/reports'
// components
import CustomDataTable from '@/components/ui/CustomDataTable'
import ResportsStickersCounterItem from '@/components/elements/reports/ResportsStickersCounterItem'

export default {
  name: 'ReportsStickersCounter',
  components: {
    CustomDataTable,
    ResportsStickersCounterItem
  },
  props: {
    areas: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      stickersCounters: null,
      headers: [
        {
          text: 'Área',
          value: 'area',
          width: 50,
          align: 'center'
        },
        {
          text: 'Pequeñas (Impresos / Asignados)',
          value: 'assigned',
          align: 'center'
        },
        {
          text: 'Grandes (Impresos / Asignados)',
          value: 'printed',
          align: 'center'
        }
      ]
    }
  },

  async mounted() {
    const stickers = await getStickersCounter()
    this.stickersCounters = await stickers.data.reduce(
      (sumCounter, counter) => {
        const counterIndex = this.areas.findIndex(area => area === counter.area)
        if (counterIndex > -1) {
          sumCounter.push(counter)
        }
        return sumCounter
      },
      []
    )
  }
}
</script>
