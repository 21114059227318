// Constants
import { MANAGEMENTS } from '@/constants'
// Models
import Statistic from '@/firebase/models/statistic'
// Services
import { getAllCompanyKeysWithCompanyActivation } from '@/services/companyKeys'

const StatisticDb = new Statistic()

const moment = require('moment')

/**
 * Get statistic by UID
 *
 * @param {string} id - UID statistic in database
 */
export async function getStatisticById(id) {
  const statistic = await StatisticDb.read(id)
  return statistic
}

/**
 * Get Schweppes KPI's about "companyKeys"
 *
 * @param {Date} dateFrom - date from
 * @param {Date} dateTo - date to
 * @param {string} area - date to
 * @return {array} - results array
 */
export async function getCompanyKeysKPITotal(
  dateFrom = moment('2020-06-01').format('YYYY-MM-DD'),
  dateTo = moment()
    .subtract(1, 'days')
    .toDate(),
  area = '1'
) {
  const statistics = await StatisticDb.readCompanyKeysKPI(
    dateFrom,
    dateTo,
    area
  )
  // Obtenemos del servicio todos los companyKeys que tienen alguna activación pendiente
  const allCompanyKeysWithActivationPendingByArea = await getAllCompanyKeysWithCompanyActivation(
    dateFrom,
    dateTo,
    area
  )

  // Construimos un array con todos los managements del area y los valores de cada tipo en 0
  const managements = await Promise.all(
    MANAGEMENTS[area].map(async management => {
      return {
        id: management,
        self: 0,
        assistant: 0,
        rejected: 0
      }
    })
  )

  // construimos un objeto de totales de tipos por area y por management
  const totalCompanyKeysWithActivationsByType = await allCompanyKeysWithActivationPendingByArea.reduce(
    (sumTypeActivations, companyKeyActivation) => {
      // Cogemos el tipo de activaión
      const type = companyKeyActivation.activationType
      // Aumentamos el contador total del área de ese tipo de activación
      sumTypeActivations.total[type] += 1
      // comprobamos que el campo management no sea undefined y si lo es lo creamos null
      let companyKeyManagement = companyKeyActivation.management
      if (!companyKeyActivation.management) companyKeyManagement = null
      // Buscamos el indice del elemento que contiene el management.id actual si es que existe
      const totalSelfIndex = sumTypeActivations.managements.findIndex(
        sum => sum.id === companyKeyManagement
      )
      // Si existe el indice lo único que hacemos es sumar 1
      if (totalSelfIndex >= 0) {
        sumTypeActivations.managements[totalSelfIndex][type] += 1
      }

      return sumTypeActivations
    },
    {
      total: {
        self: 0,
        assistant: 0,
        rejected: 0
      },
      managements
    }
  )

  // Parse (sum every item to get a total)
  return statistics.reduce(
    (sumStats, stat) => {
      sumStats.value += stat.value
      // usando el objeto construido en el paso anterior lo usamos para coger los totales por area
      sumStats.self = totalCompanyKeysWithActivationsByType.total.self
      sumStats.assistant = totalCompanyKeysWithActivationsByType.total.assistant
      sumStats.rejected = totalCompanyKeysWithActivationsByType.total.rejected
      // comprobamos que para el valor acumulado actual exista un array de managements sino lo añadimos
      if (!sumStats.managements) {
        sumStats.managements = stat.managements
      }
      // mapeamos el stat.managements para actualizar los contadores de cada tipo de activación
      stat.managements.map(async management => {
        // primero buscamos el indice del acumulado en el sumStats.managements con el management del mapeo
        const managementIndex = sumStats.managements.findIndex(
          man => man.id === management.id
        )
        /**
         * Ahora con el management del mapeo buscamos en el array de managements contenido
         * en el objeto totalCompanyKeysWithActivationsByType
         */
        const managementTypeActivationIndex = totalCompanyKeysWithActivationsByType.managements.findIndex(
          man => man.id === management.id
        )
        /**
         * Una vez obtenidos los 2 indices actualizamos sumStats.managements con su valor actual
         * y le añadimos el sumatorio de tipos de activación
         */
        sumStats.managements[managementIndex] = {
          ...sumStats.managements[managementIndex],
          ...totalCompanyKeysWithActivationsByType.managements[
            managementTypeActivationIndex
          ]
        }

        sumStats.managements[managementIndex].value += management.value
      })
      return sumStats
    },
    { area, value: 0, managements: null }
  )
}
/**
 * Get counter of stickers
 */
export async function getStickersCounter() {
  const result = await StatisticDb.getStickersNumbers()
  return result
}
