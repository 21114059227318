// Constants
import { PREFIX_COLLECTIONS } from '@/constants'
// Firebase and DB
import GenericDB from '../generic-db'
import firestore from '../async-firestore'

const moment = require('moment')

export default class Statistic extends GenericDB {
  // Each "defaultModel" must be defined in each Class
  defaultModel = {
    counters: null
  }

  constructor() {
    super(`${PREFIX_COLLECTIONS.horeca}statistics`)
  }

  /**
   * Return KPI's data (companyKeys)
   *
   * @param {Date} dateFrom - date from
   * @param {Date} dateTo - date to
   * @param {string} area - date to
   * @return {array} - results array
   */
  async readCompanyKeysKPI(dateFrom, dateTo, area) {
    if (dateFrom instanceof Date && dateTo instanceof Date && area) {
      const dateF = moment(dateFrom)
        .startOf('day')
        .toDate()
      const dateT = moment(dateTo)
        .endOf('day')
        .toDate()
      const ref = (await firestore())
        .collection(this.collectionPath)
        .doc(`${process.env.VUE_APP_BRAND}_counters`)
        .collection('kpis')
        .doc('companyKeys')
        .collection('data')

      // Buscamos los fragmentos del tipo de contador
      const result = await ref
        .where('area', '==', area)
        .where('date', '>=', dateF)
        .where('date', '<=', dateT)
        .get()
        .then(items => {
          return items.docs.map(item =>
            this.convertObjectTimestampPropertiesToDate({
              id: item.id,
              ...item.data()
            })
          )
        })
      return result
    }
    return []
  }

  /**
   * Return KPI's data (companyActivations)
   *
   * @param {Date} dateFrom - date from
   * @param {Date} dateTo - date to
   * @param {string} type - companyActivations type
   * @param {string} area - trader area
   * @return {array} - results array
   */
  async readCompanyActivationsKPI(dateFrom, dateTo, area, type) {
    if (dateFrom instanceof Date && dateTo instanceof Date && type && area) {
      const dateF = moment(dateFrom)
        .startOf('day')
        .toDate()
      const dateT = moment(dateTo)
        .endOf('day')
        .toDate()
      const ref = (await firestore())
        .collection(this.collectionPath)
        .doc(`${process.env.VUE_APP_BRAND}_counters`)
        .collection('kpis')
        .doc('companyActivations')
        .collection('data')

      // Buscamos los fragmentos del tipo de contador
      const result = await ref
        .where('area', '==', area)
        .where('type', '==', type)
        .where('date', '>=', dateF)
        .where('date', '<=', dateT)
        .get()
        .then(items => {
          return items.docs.map(item =>
            this.convertObjectTimestampPropertiesToDate({
              id: item.id,
              ...item.data()
            })
          )
        })

      return result
    }
    return []
  }

  /**
   * Return KPI's data (stickersNumbers)
   */
  async getStickersNumbers() {
    const ref = (await firestore())
      .collection(this.collectionPath)
      .doc(`${process.env.VUE_APP_BRAND}_counters`)
      .collection('kpis')
      .doc('StickersNumbers')

    const result = await ref.get()
    return {
      ...result.data()
    }
  }
}
