<template>
  <div v-if="searchBy.length" class="custom-data-table-seacher">
    <v-container>
      <v-row>
        <v-col cols="6">
          <v-select
            v-model="queryField"
            outlined
            dense
            :items="searchBy"
            item-value="id"
            item-text="label"
            label="Filtrar por"
            hide-details
            @change="handleChangeQueryField"
          />
        </v-col>
        <v-col cols="5">
          <template
            v-if="
              queryField && (!queryField.type || queryField.type === 'text')
            "
          >
            <v-text-field
              v-model="query"
              outlined
              dense
              label="Buscar por..."
              single-line
              hide-details
            />
          </template>
          <template v-if="queryField && queryField.type === 'select'">
            <v-select
              v-model="query"
              outlined
              dense
              :items="queryField.values"
              item-value="value"
              item-text="label"
              label="Buscar por..."
              hide-details
              clearable
            />
          </template>
        </v-col>
        <v-col cols="1">
          <v-btn
            color="primary"
            elevation="0"
            block
            @click="handleSearchDebounce"
          >
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// Utils
import { debounce, isNil } from 'lodash'

export default {
  name: 'CustomDataTableSearcher',
  props: {
    searchBy: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      // Search (query)
      query: null,
      queryField: null
    }
  },
  mounted() {
    this.setInitialOptions()
  },
  methods: {
    /**
     * Handle "queryField" change
     *
     * @param {string} field - field to search
     */
    handleChangeQueryField(field) {
      const currentSearchField = this.searchBy.find(searchField => {
        return searchField.id === field
      })
      // Set and reset
      this.queryField = currentSearchField || this.searchBy[0]
      this.query = null
      // Emit to reset previous search
      this.$emit('onSearch', {
        query: null
      })
    },
    /**
     * Handle search
     */
    handleSearchDebounce: debounce(function handleSearch() {
      this.$emit('onSearch', {
        query: !isNil(this.query)
          ? this.formatQuery(this.query, this.queryField.format)
          : null,
        queryField: this.queryField.id,
        typeSearch: this.queryField.typeSearch
      })
    }, 350),
    /**
     * Apply format to string to use in query
     *
     * @param {string} value
     * @param {string} format - string, number, boolean
     * @return {string | number | boolean}
     */
    formatQuery(value, format = 'string') {
      if (value !== '') {
        switch (format) {
          case 'string':
            value = String(value)
            break
          case 'number':
            value = Number.parseInt(value, 10)
            break
          case 'boolean':
            value = Boolean(value)
            break
          default:
            value = String(value)
        }
      }

      return value
    },
    /**
     * Set the initial options
     */
    setInitialOptions() {
      this.queryField =
        this.searchBy && this.searchBy.length ? this.searchBy[0] : null
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
.custom-data-table-searcher {
  background: #fff;
  padding: 0 0.5rem;
}
</style>
