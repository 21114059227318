<template>
  <div class="custom-data-table">
    <!-- Search -->
    <CustomDataTableSearcher :search-by="searchBy" @onSearch="handleSearch" />
    <!-- Data table -->
    <v-data-table
      :headers="getHeadersData"
      :items="items"
      v-bind="getDataTableVOptions"
      :loading="loading"
      class="elevation-0"
    >
      <template v-slot:body="{ items }">
        <slot
          :items="items"
          :firstItem="getFirstItem"
          :lastItem="getLastItem"
        ></slot>
      </template>
      <template
        v-if="getDataTableVOptions['show-custom-paginate']"
        v-slot:footer
      >
        <!-- Pagination -->
        <div class="pagination">
          <v-select
            :value="itemsPerPage"
            class="items-per-page"
            :items="itemsPerPageOptions"
            dense
            label="Registros por página"
            hide-details
            @change="handleChangeItemsPerPage"
          />
          <div class="pager">
            <v-btn icon :disabled="page === 1" @click="handleClickPrevPage">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <span class="number">{{ page }}</span>
            <v-btn
              icon
              :disabled="!areThereMoreItems"
              @click="handleClickNextPage"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
// Components
import CustomDataTableSearcher from '@/components/ui/CustomDataTableSearcher'

export default {
  name: 'CustomDataTable',
  components: { CustomDataTableSearcher },
  props: {
    headers: {
      type: Array,
      default() {
        return []
      }
    },
    /**
     * https://vuetifyjs.com/en/components/data-tables/#data-tables
     */
    dataTableVOptions: {
      type: Object,
      default() {
        return {}
      }
    },
    items: {
      type: Array,
      default() {
        return []
      }
    },
    itemsPerPage: {
      type: Number,
      default: 20
    },
    loading: {
      type: Boolean,
      default: true
    },
    page: {
      type: Number,
      default: 1
    },
    searchBy: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      // Current page in table
      counterPage: 1,
      itemsPerPageOptions: [20, 40, 60, 80, 100]
    }
  },
  computed: {
    /**
     * Return options to v-data-table
     * https://vuetifyjs.com/en/components/data-tables/#data-tables
     */
    getDataTableVOptions() {
      return {
        'disable-sort': true,
        'hide-default-footer': true,
        'show-custom-paginate': true,
        'items-per-page': this.itemsPerPage || 20,
        'loading-text': 'Cargando elementos...',
        'no-data-text': 'Sin datos',
        ...this.dataTableVOptions
      }
    },
    /**
     * Are there more items to show the "next" page?
     */
    areThereMoreItems() {
      return this.items.length === this.itemsPerPage
    },
    /**
     * Get the first item in list
     *
     * @return {object} - first item in list
     */
    getFirstItem() {
      return this.items && this.items.length ? this.items[0] : false
    },
    /**
     * Get the last item in list
     *
     * @return {object} - last item in list
     */
    getLastItem() {
      return this.items && this.items.length
        ? this.items[this.items.length - 1]
        : false
    },
    /**
     * Get headers data depending of "items" and "headers"
     *
     * La idea de esta función es auto-generar las cabeceras ("headers")
     * que necesita la tabla mediante el atributo "items", indicando
     * que cabeceras deseamos mostrar y cuales no.
     */
    getHeadersData() {
      const customHeaders = this.headers
      const areThereCustomHeaders =
        Array.isArray(customHeaders) && customHeaders.length > 0
      let parsedHeaderData = []

      if (this.items && this.items.length) {
        const itemData = this.items[0]

        if (typeof itemData === 'object' && itemData !== null) {
          // Incluyo, en el array a devolver los items que existen en
          // "customHeaders" pero que no existen en "itemData"
          // ("value" no tiene atributo )
          parsedHeaderData = customHeaders.filter(item => !item.value)
          // Ahora emparejamos los datos de "items" con los datos de "customHeaders"
          parsedHeaderData = Object.keys(itemData).reduce(
            (sumHeaderDataKey, headerDataKey) => {
              // Valores por defecto tomados de "items"
              const currentHeaderData = {
                text: headerDataKey,
                value: headerDataKey
              }

              if (areThereCustomHeaders) {
                const headerDataIndex = customHeaders.findIndex(item => {
                  return item.value === headerDataKey
                })

                if (headerDataIndex > -1) {
                  sumHeaderDataKey.push({
                    ...currentHeaderData,
                    ...customHeaders[headerDataIndex]
                  })
                }
              } else {
                sumHeaderDataKey.push(currentHeaderData)
              }

              return sumHeaderDataKey
            },
            parsedHeaderData
          )
        }
        // Debido al parseo, el orden de los objetos dentro del array
        // cambia según lo indicado por el usuario y debemos reordenarlos
        // como el usuario indica
        if (areThereCustomHeaders) {
          parsedHeaderData.sort((a, b) => {
            const aIndex = customHeaders.findIndex(
              item => item.value === a.value
            )
            const bIndex = customHeaders.findIndex(
              item => item.value === b.value
            )
            return aIndex - bIndex
          })
        }
      } else {
        parsedHeaderData = this.headers
      }

      return parsedHeaderData
    }
  },
  mounted() {
    this.setInitialOptions()
  },
  methods: {
    /**
     * Handle click in next page
     */
    handleClickPrevPage() {
      this.counterPage = this.counterPage - 1 <= 1 ? 1 : this.counterPage - 1
      this.$emit('onPrevPage', {
        page: this.counterPage,
        prev: this.getFirstItem,
        limit: this.itemsPerPage
      })
    },
    /**
     * Handle click in next page
     */
    handleClickNextPage() {
      this.counterPage += 1
      this.$emit('onNextPage', {
        page: this.counterPage,
        next: this.getLastItem,
        limit: this.itemsPerPage
      })
    },
    /**
     * Handle change items per page
     */
    handleChangeItemsPerPage(value) {
      this.query = null
      this.$emit('onChangeItemsPerPage', {
        page: 1,
        limit: value
      })
    },
    /**
     * Handle search
     *
     * @param {object} $event - searcher event
     */
    handleSearch($event) {
      this.$emit('onSearch', $event)
    },
    /**
     * Set the initial options
     */
    setInitialOptions() {
      this.queryField =
        this.searchBy && this.searchBy.length ? this.searchBy[0] : null
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
.custom-data-table {
  width: 100%;
  padding: 0 30px;
  background: #fff;
  flex: 1;
  .items-per-page {
    max-width: 150px;
  }
  .pagination {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 1rem;
    border-top: 1px solid #000;
    .number {
      margin: 0 0.5rem;
    }
  }
}
</style>
