<template>
  <div class="vuetify-date-picker">
    <v-menu
      ref="menuDatePicker"
      v-model="toogleDatePicker"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      :max-width="`${datePickerMaxWidth}px`"
      :min-width="`${datePickerMinWidth}px`"
    >
      <!-- Añadimos un Select con algunos días por defecto -->
      <div class="px-4 pt-6 white text-center">
        <v-select
          v-model="predefinedDate"
          :items="selectOptions"
          item-value="id"
          item-text="name"
          outlined
          dense
          style="width: 260px"
          placeholder="Rangos predeterminados"
          @change="selectLastDays"
        ></v-select>
      </div>
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="computedDateFormatted"
          :label="dateInputLabel"
          persistent-hint
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
        />
      </template>
      <v-date-picker
        v-model="currentDate"
        v-bind="datePickerVOptions"
        :allowed-dates="allowedDates"
      >
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="hideDatePicker">
          {{ buttonCancelLabel }}
        </v-btn>
        <v-btn text color="primary" @click="handleClickOk">
          {{ buttonAcceptLabel }}
        </v-btn>
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
const moment = require('moment')

export default {
  name: 'VuetifyDatePicker',
  props: {
    allowedDates: {
      type: Function,
      default: () => {
        return true
      }
    },
    date: {
      type: [Array, Object],
      default() {
        return []
      }
    },
    dateFormat: {
      type: String,
      default: 'DD-MM-YYYY'
    },
    dateInputLabel: {
      type: String,
      value: 'Fechas seleccionadas'
    },
    datePickerMaxWidth: {
      type: Number,
      value: 290
    },
    datePickerMinWidth: {
      type: Number,
      value: 290
    },
    /**
     * Debe ser pasado desde el padre con una variable computada
     * https://vuetifyjs.com/en/components/date-pickers/#date-month-pickers
     */
    datePickerVOptions: {
      default() {
        return {
          'no-title': true,
          'first-day-of-week': 1,
          range: true
        }
      },
      type: Object
    },
    buttonAcceptLabel: {
      type: String,
      default: 'ok'
    },
    buttonCancelLabel: {
      type: String,
      default: 'Cancelar'
    }
  },
  data() {
    return {
      currentDate: this.date,
      toogleDatePicker: false,
      predefinedDate: 7,
      selectOptions: [
        {
          id: 1,
          name: 'Ayer'
        },
        {
          id: 7,
          name: 'Últimos 7 Días'
        },
        {
          id: 15,
          name: 'Últimos 15 Días'
        },
        {
          id: 30,
          name: 'Últimos 30 Días'
        }
      ]
    }
  },
  computed: {
    /**
     * Format the date to show in input
     *
     * @return {string} - formated date
     */
    computedDateFormatted() {
      return this.getDateFormatted(this.currentDate)
    }
  },
  watch: {
    /**
     * Watch change currentDate to format date
     *
     * @return {string} - formated date
     */
    currentDate() {
      this.dateFormatted = this.getDateFormatted(this.currentDate)
    }
  },
  methods: {
    /**
     * Handle click on OK button
     */
    handleClickOk() {
      if (Array.isArray(this.currentDate) && this.currentDate.length === 2) {
        this.$emit('onChangeDate', this.currentDate)
        this.hideDatePicker()
      }
    },
    /**
     * Hide date picker
     */
    hideDatePicker() {
      this.toogleDatePicker = false
    },
    /**
     * Set currentDate from select
     */
    selectLastDays(days) {
      this.currentDate = [
        moment()
          .subtract(days, 'days')
          .startOf('day')
          .format('YYYY-MM-DD'),
        moment()
          .subtract(1, 'days')
          .endOf('day')
          .format('YYYY-MM-DD')
      ]
      this.handleClickOk()
    },
    /**
     * Get the date formated
     *
     * @param {string | array} - dates to parse
     * @return {string} - formated date
     */
    getDateFormatted(date) {
      if (Array.isArray(date) && date.length > 0) {
        // Calculamos la diferencia de días para controlar el orden en el que seleccionan las fechas
        let diffSelected = moment(date[0])
          .startOf('day')
          .diff(moment(date[1]).startOf('day'), 'days')
        /**
          Si la primera fecha es mayor a la segunda es positivo 
          por lo que cambiamos el orden a mostrar en el string del input
        */
        if (date[1] && diffSelected > 0) {
          date = [date[1], date[0]]
        }
        /**
          Si la diferencia es negativa cambiamos el signo para modificar el select. 
          Al número resultante le sumamos un 1 y si coincide con alguna opción se mostrará en select
          en caso contrario el select se quedará vacío
        */
        if (diffSelected > 0) diffSelected *= -1
        this.predefinedDate = diffSelected + 1
        return date
          .reduce((sumDates, d) => {
            sumDates.push(moment(d).format(this.dateFormat))
            return sumDates
          }, [])
          .join(' ~ ')
      }

      return moment(date).format(this.dateFormat)
    }
  }
}
</script>
