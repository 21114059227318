var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PanelSection',{attrs:{"title":"Contadores generales"}},[_c('v-container',[_c('v-card',{staticClass:"elevation-0 mb-4"},[_c('v-card-text',[_c('v-card-title',{staticClass:"pt-0"},[_c('span',[_vm._v("Resumen de Etiquetas QR")])]),(_vm.getTraderAreas())?_c('ReportsStickersCounter',{attrs:{"areas":_vm.getTraderAreas()}}):_vm._e()],1)],1),_c('v-card',{staticClass:"elevation-0 mb-4"},[_c('v-card-title',{staticClass:"pt-0"},[_c('VuetifyDatePicker',{attrs:{"date":_vm.date,"allowed-dates":_vm.allowedDates},on:{"onChangeDate":_vm.handleChangeDate}})],1),_c('v-card-text',[(!_vm.companyKeysKPI)?_c('ContentLoading',{staticClass:"mb-6",attrs:{"loading":_vm.loading}}):_c('div',{staticClass:"elevation-0"},[(_vm.isAdmin)?_c('div',[_c('v-card-title',[_c('span',[_vm._v("Resumen de uso total por Áreas")]),_c('v-spacer'),_c('v-btn',{staticClass:"elevation-0 secondary mr-2",attrs:{"loading":_vm.downloadLoadingKPIs['all'],"small":""},on:{"click":function($event){return _vm.exportKPIsToExcel('all')}}},[_vm._v(" Exportar KPIs ")]),_c('v-btn',{staticClass:"elevation-0 primary",attrs:{"loading":_vm.downloadLoading['all'],"small":""},on:{"click":function($event){return _vm.exportToExcel('all')}}},[_vm._v(" Exportar detalles ")])],1),_c('CustomDataTable',{attrs:{"headers":_vm.headersDataByArea,"items":_vm.companyKeysKPI,"loading":_vm.loading,"data-table-v-options":{
                'show-custom-paginate': false,
                dense: true
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var items = ref.items;
return [_c('ReportsListItem',{attrs:{"items":items}})]}}],null,false,1938959369)})],1):_vm._e(),_c('div',{staticClass:"mb-6",class:{ 'mt-8': _vm.isAdmin }},[_c('v-card-title',[_c('span',[_vm._v("Resumen de uso total en detalle por Gerencia")])]),_vm._l((_vm.companyKeysKPI),function(companyKey,i){return _c('div',{key:("company-keys-area-" + i)},[_c('v-card-title',[_c('h5',[_vm._v("Área "+_vm._s(companyKey.area))]),_c('v-spacer'),_c('v-btn',{staticClass:"elevation-0 secondary mr-2",attrs:{"loading":_vm.downloadLoadingKPIs[companyKey.area],"small":""},on:{"click":function($event){return _vm.exportKPIsToExcel(companyKey.area)}}},[_vm._v(" Exportar KPIs - área "+_vm._s(companyKey.area)+" ")]),_c('v-btn',{staticClass:"elevation-0 primary",attrs:{"loading":_vm.downloadLoading[companyKey.area],"small":""},on:{"click":function($event){return _vm.exportToExcel(companyKey.area)}}},[_vm._v(" Exportar detalles - área "+_vm._s(companyKey.area)+" ")])],1),_c('div',{staticClass:"mb-6"},[_c('CustomDataTable',{attrs:{"headers":_vm.headersDataByManagement,"items":companyKey.managements,"loading":_vm.loading,"data-table-v-options":{
                    'show-custom-paginate': false,
                    dense: true
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var items = ref.items;
return [_c('ReportsListItem',{attrs:{"items":items}})]}}],null,true)})],1)],1)})],2)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }